<template>
    <div>
        <div v-if="isRemote" data-testid="profile-location-preference-remote-container" class="profile-workplace-preferences__wrapper">
            <VJoyIcon name="home" />
            <dl class="profile-workplace-preferences__item" data-testid="profile-location-remote">
                <dt>{{ t('profile.preferences.section.work_place.remote.title') }}</dt>
                <dd>{{ t('profile.preferences.section.work_place.remote.label') }}</dd>
            </dl>
        </div>

        <div v-else class="profile-workplace-preferences__wrapper" data-testid="profile-location-preference-onsite-container">
            <VJoyIcon name="walk" />
            <dl class="profile-workplace-preferences__item" data-testid="profile-location-work-place">
                <dt v-if="modelValue.willingnessToTravelInternationally" data-testid="profile-location-work-place-willingness-to-travel">
                    {{ t('profile.preferences.section.workplace.preferences.international.willingnessToTravel') }}
                </dt>
                <dt v-else data-testid="profile-location-work-place-default">{{ t('profile.preferences.section.work_place.onsite.title') }}</dt>
                <dd v-if="!modelValue.willingnessToTravelInternationally">
                    <ul>
                        <li>
                            {{
                                t('mission.preferences.screen.localisation.location.with.radius.label', {
                                    0: modelValue.city,
                                    1: `${modelValue.addressRadius}`,
                                })
                            }}
                        </li>
                        <li v-for="item in modelValue.activityLocations" :key="item.zipcode">
                            {{
                                t('mission.preferences.screen.localisation.location.with.radius.label', {
                                    0: item.city,
                                    1: `${item.radius}`,
                                })
                            }}
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useTranslation} from '#imports';
    import type {LocationPreferencesItem} from '~/components/_common/location-and-workplace-preferences/LocationPreferencesItem';
    import {WorkplacePreferenceItem} from '~/components/_common/location-and-workplace-preferences/LocationPreferencesItem';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const props = defineProps<{
        modelValue: LocationPreferencesItem;
    }>();

    const {t} = useTranslation();
    const isRemote = computed(() => props.modelValue.preference === WorkplacePreferenceItem.REMOTE);
</script>

<style lang="scss">
    .profile-workplace-preferences {
        &__wrapper {
            display: flex;
            align-items: baseline;
            column-gap: 8px;
        }

        &__item {
            ul,
            li {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            dd {
                margin: var(--joy-core-spacing-2) 0 0 0;
                font-size: var(--joy-font-size-primary-300);
            }

            dt {
                font-size: var(--joy-font-size-primary-400);
                font-weight: var(--joy-font-weight-bold);
            }
        }
    }
</style>
