export type ExperienceItem = {
    company: string;
    title: string;
    activityArea?: string;
    location?: string;
    startDate?: Date;
    endDate?: Date;
    currentJob: boolean;
    description?: string;
    tags: string[];
    logoSrc?: string;
};

export enum ExperienceSize {
    SMALL = 'small',
    LARGE = 'large',
}
