<template>
    <div class="profile__location-and-workplace-preferences__wrapper">
        <VJoyIcon name="location" />
        <dl class="profile__location-and-workplace-preferences__item" data-testid="profile-location-address" data-profile-location-preference>
            <dt data-testid="profile-location-address-label">{{ t('profile.section.header.location.label') }}</dt>
            <dd data-testid="profile-location-preference-address">{{ modelValue }}</dd>
        </dl>
    </div>
</template>
<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const {t} = useTranslation();

    defineProps<{
        modelValue?: string;
    }>();
</script>
<style lang="scss">
    .profile__location-and-workplace-preferences {
        &__wrapper {
            display: flex;
            align-items: baseline;
            column-gap: 8px;
        }

        &__item {
            width: 100%;
            margin: 0;
            padding: 0;

            ul,
            li {
                list-style: none;
                margin: 0;
                padding: 0;
            }
            dd {
                margin: var(--joy-core-spacing-2) 0 0 0;
                font-size: var(--joy-font-size-primary-300);
            }
            dt {
                font-size: var(--joy-font-size-primary-400);
                font-weight: var(--joy-font-weight-bold);
            }
        }
    }
</style>
