import type {Languages} from '~/types/domain/Languages';

export type LanguageItem = {
    code: string;
    name: string;
    level: string;
};

export function toLanguageItems(languages: Languages): LanguageItem[] {
    return languages.items.map((language) => {
        return {
            code: language.language.code,
            name: language.language.name,
            level: language.level,
        };
    });
}
