import {inject, ref, type Ref} from 'vue';
import {type ProfileExpertises, type ProfileSelectedSkillSetItem} from '~/types/domain/ProfileExpertises';
import {RegisteredRepositories} from '@/config/application.services';
import {defineStore, storeToRefs} from 'pinia';
import {useProfileStore} from '../profileStore';
import {Configuration, ExpertiseApi} from '@api';
import {expertiseApiMapper} from '~/features/expertises/helpers/ExpertiseApiMapper';

export const useSkillSetStore = defineStore('skill-set', () => {
    const profileRepository = inject(RegisteredRepositories.injectionKeys.profile)!;
    const apiConfiguration = new Configuration({basePath: ''});
    const expertiseApi = new ExpertiseApi(apiConfiguration);

    const {profile} = storeToRefs(useProfileStore());

    const profileExpertises = ref<ProfileExpertises>();
    const profileSelectedSkills = ref<ProfileSelectedSkillSetItem[]>([]);
    const profileTopSkills = ref<ProfileSelectedSkillSetItem[]>([]);

    async function fetchSkillSet(): Promise<{
        profileSelectedSkills: Ref<ProfileSelectedSkillSetItem[]>;
        profileTopSkills: Ref<ProfileSelectedSkillSetItem[]>;
    }> {
        profileExpertises.value = await profileRepository.fetchProfileExpertises(profile.value!);

        profileSelectedSkills.value = profileExpertises.value.selectedSkills;
        profileTopSkills.value = profileExpertises.value.topSkills;

        return {
            profileSelectedSkills,
            profileTopSkills,
        };
    }

    async function saveSkillSet(newSelectedSkills: ProfileSelectedSkillSetItem[], newTopSkills: ProfileSelectedSkillSetItem[]): Promise<void> {
        await expertiseApi.updateSkills(expertiseApiMapper.toUpdateSkillsRequestApi(newSelectedSkills, newTopSkills));
        profileSelectedSkills.value = [...newSelectedSkills];
        profileTopSkills.value = [...newTopSkills];
    }

    function initializeStore() {
        if (profileExpertises.value) {
            if (profileExpertises.value.selectedSkills) {
                profileSelectedSkills.value = [...profileExpertises.value.selectedSkills];
            }

            if (profileExpertises.value.topSkills) {
                profileTopSkills.value = [...profileExpertises.value.topSkills];
            }
        }
    }

    return {
        profileTopSkills,
        profileSelectedSkills,
        fetchSkillSet,
        initializeStore,
        saveSkillSet,
    };
});
