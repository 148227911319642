<template>
    <div class="profile-skills-read-only" :data-testid="`${props.dataTestid}-list`">
        <ProfileSkillSetItem
            v-for="(skill, index) in props.skills"
            :key="`profile-main-top-skill-${index}`"
            :data-testid="`${props.dataTestid}-${index}`"
            :href="getSeoUrl(skill)"
        >
            {{ getLabel(skill) }}
        </ProfileSkillSetItem>
    </div>
</template>

<script setup lang="ts">
    import {toRef} from '#imports';
    import ProfileSkillSetItem from '@/components/_common/skill-set/ProfileSkillSetItem.vue';

    export type Skill = {label: string; translatedLabel?: string; seoUrl?: string; id: string};

    interface Props {
        dataTestid: string;
        isVisibleSeoLink?: boolean;
        isVisibleTranslatedLabel?: boolean;
        skills: Array<Skill>;
    }
    const props = withDefaults(defineProps<Props>(), {isVisibleSeoLink: false, isVisibleTranslatedLabel: false});
    const isVisibleTranslatedLabel = toRef(props, 'isVisibleTranslatedLabel');

    function getSeoUrl(skill: Skill): string | undefined {
        return (!!props.isVisibleSeoLink && skill.seoUrl) || undefined;
    }
    function getLabel(skill: Skill) {
        return isVisibleTranslatedLabel.value && skill.translatedLabel ? skill.translatedLabel : skill.label;
    }
</script>

<style lang="scss">
    .profile-skills-read-only {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: var(--joy-core-spacing-4);
    }
</style>
