<template>
    <ul class="profile-languages">
        <li v-for="(language, index) in languages" :key="language.code" class="profile-languages__item">
            <p class="profile-languages__item__title" :data-testid="`profile-language-name-${index}`">{{ language.name }}</p>
            <p class="profile-languages__item__subtitle" :data-testid="`profile-language-level-${index}`">
                {{ t(`profile.new.language.level.${language.level}`) }}
            </p>
        </li>
    </ul>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import type {LanguageItem} from '~/components/_common/language/LanguageItem';

    const {t} = useTranslation();

    export interface Props {
        languages?: LanguageItem[];
    }

    withDefaults(defineProps<Props>(), {
        languages: () => [],
    });
</script>

<style lang="scss">
    .profile-languages {
        list-style-type: none;
        padding: 0;
        margin-top: var(--joy-core-spacing-2);

        &__item {
            padding: var(--joy-core-spacing-4);
            margin-bottom: var(--joy-core-spacing-2);

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }

            &__title {
                font-family: var(--joy-font-family);
                font-weight: var(--joy-font-weight-bold);
                font-size: var(--joy-font-size-primary-600);
                line-height: var(--joy-line-height-large);
                margin-bottom: 0;
            }

            &__subtitle {
                font-size: var(--joy-font-size-primary-300);
                margin-bottom: var(--joy-core-spacing-1);
                color: var(--joy-color-text-body);
            }
        }
    }
</style>
