export type EducationItem = {
    description: string;
    title: string;
    graduationYear?: number;
    content: string;
};

export enum EducationSize {
    SMALL = 'small',
    LARGE = 'large',
}
