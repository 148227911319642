<template>
    <div class="profile-experience-item" :class="`profile-experience-item--${size}`">
        <MaltImg
            v-if="experience.logoSrc"
            class="profile-experience-item__icon"
            :class="`profile-experience-item__icon--${size}`"
            width="46"
            height="46"
            :src="experience.logoSrc"
            alt=""
        />
        <VJoyIcon
            v-else
            :size="size"
            name="company-placeholder"
            class="profile-experience-item__icon"
            :class="`profile-experience-item__icon--${size}`"
        />
        <div class="profile-experience-item__content">
            <div class="profile-experience-item__content__title" :class="`profile-experience-item__content__title--${size}`">
                <div class="profile-experience-item__content__title__company" data-testid="profile-experience-item-company">
                    {{ experience.company }}
                </div>
            </div>
            <div class="profile-experience-item__content__subtitle" data-testid="profile-experience-item-job">
                {{ experience.title }}
            </div>
            <div
                v-if="showActivityArea"
                class="profile-experience-item__content__activity-area joy-mb-4"
                data-testid="profile-experience-item-activity-area"
            >
                {{ t(`company.activity.area.${experience.activityArea}`).toUpperCase() }}
            </div>
            <div class="profile-experience-item__content__others">
                <ExperienceItemDateRange
                    v-if="showDateRange"
                    :start-date="experience.startDate!"
                    :end-date="experience.endDate"
                    :current="experience.currentJob"
                />
                <template v-if="showLocation">
                    <div data-testid="profile-experience-item-location">
                        {{ experience.location }}
                    </div>
                </template>
            </div>
            <div class="profile-experience-item__content__description">
                <!-- eslint-disable vue/no-v-html -->
                <div v-if="showDescription" data-testid="profile-experience-item-description" v-html="sanitizedDescription"></div>
            </div>

            <VJoyTagsList v-if="showTags" class="joy-mt-4" variant="secondary" :tags="formatTags(experience.tags)" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useMarkdownToHtml, useTranslation} from '#imports';
    import ExperienceItemDateRange from '~/components/_common/experience/ExperienceItemDateRange.vue';
    import type {ExperienceItem} from '~/components/_common/experience/ExperienceItem';
    import {ExperienceSize} from '~/components/_common/experience/ExperienceItem';
    import {VJoyIcon, VJoyTagsList} from '@maltjoy/core-vue';
    import {MaltImg} from '#components';

    const {t} = useTranslation();
    const {markdownToHtml} = useMarkdownToHtml();

    export interface Props {
        size?: ExperienceSize;
        experience: ExperienceItem;
    }

    const props = withDefaults(defineProps<Props>(), {
        size: ExperienceSize.LARGE,
    });

    const showActivityArea = computed(() => !!props.experience.activityArea);
    const showLocation = computed(() => !!props.experience.location);
    const showDateRange = computed(() => !!props.experience.startDate);
    const showDescription = computed(() => !!props.experience.description);
    const showTags = computed(() => props.experience.tags.length > 0);

    const sanitizedDescription = computed(() => markdownToHtml(props.experience.description));

    function formatTags(tags?: string[]) {
        return (
            tags?.map((tag) => {
                return {
                    value: tag.toLowerCase(),
                    label: tag,
                };
            }) || []
        );
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-experience-item {
        display: flex;
        flex: 1 1 auto;

        &--large {
            @include mq.screen_xs() {
                flex-direction: column-reverse;
            }
        }

        &--small {
            @include mq.screen_xs() {
                flex-direction: row;
            }

            @include mq.screen_xxs() {
                flex-direction: column-reverse;
            }
        }

        &__icon {
            &--large {
                @include mq.screen_xs() {
                    display: none;
                }
            }

            &--small {
                @include mq.screen_xs() {
                    display: block;
                }

                @include mq.screen_xxs() {
                    display: none;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: var(--joy-core-spacing-4);
            margin-left: var(--joy-core-spacing-2);

            &__title {
                display: flex;
                margin-bottom: var(--joy-core-spacing-1);
                max-width: 100%;
                margin-right: var(--joy-core-spacing-5);
                font-family: var(--joy-font-family);
                font-weight: var(--joy-font-weight-bold);
                line-height: var(--joy-line-height-large);

                &--small {
                    font-size: var(--joy-font-size-primary-400);
                }

                &--large {
                    font-size: var(--joy-font-size-primary-600);
                }

                &__company {
                    width: 100%;
                }
            }

            &__subtitle {
                font-size: var(--joy-font-size-primary-300);
                font-weight: var(--joy-font-weight-bold);
                margin-bottom: var(--joy-core-spacing-1);
                color: var(--joy-color-text-body);
                display: flex;
            }

            &__activity-area {
                white-space: nowrap;
                font-size: var(--joy-font-size-primary-200);
                font-weight: var(--joy-font-weight-normal);
            }

            &__others {
                display: flex;
                flex-direction: column;
                color: var(--joy-color-text-body);
                font-size: var(--joy-font-size-primary-300);
            }

            &__description {
                margin-top: var(--joy-core-spacing-3);
                word-break: break-word;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                a {
                    flex: 0 0 auto;
                }
            }
        }
    }
</style>
~/helpers/MarkdownToHtml ~/helpers/markdownToHtml
