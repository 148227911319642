<template>
    <div v-if="shouldShowMore" class="profile-show-more-or-less">
        <VJoyLink :icon="linkIcon" href="" :text="linkLabel" :data-testid="testId" @click.prevent="handleClick" />
    </div>
</template>

<script setup lang="ts">
    import {computed, ref, useTranslation} from '#imports';
    import {useSectionsVisibility} from '~/composables/useSectionsVisibility';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {VJoyLink} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const props = defineProps<{
        label: string;
        lockedLabel: string;
        testId: string;
        total: number;
        limit: number;
        componentName: string;
    }>();
    const emits = defineEmits<{
        (e: 'expand'): void;
        (e: 'collapse'): void;
    }>();

    const viewMoreLock = useSectionsVisibility(SectionsVisibilityType.VIEW_MORE_LOCK);
    const collapsed = ref<boolean>(true);
    const shouldShowMore = computed(() => props.total > props.limit);
    const showMoreLabel = computed(() => (viewMoreLock ? props.lockedLabel : props.label));
    const linkLabel = computed(() => (collapsed.value ? showMoreLabel.value : t('profile.section.show.less')));
    const linkIcon = computed(() => (viewMoreLock ? 'lock' : collapsed.value ? 'chevron-down' : 'chevron-up'));

    function handleClick() {
        if (viewMoreLock) {
            readMoreHandleClickNotConnectedUser();
        } else if (collapsed.value) {
            collapsed.value = false;
            emits('expand');
        } else {
            collapsed.value = true;
            emits('collapse');
        }
    }

    function readMoreHandleClickNotConnectedUser() {
        const path = window.location.pathname;

        window.location.assign(`/who-are-you?redirect=${path}&pageSource=profile_page&componentSource=${props.componentName}`);
    }
</script>

<style lang="scss">
    .profile-show-more-or-less {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--joy-core-spacing-3);
        margin-top: var(--joy-core-spacing-3);
    }
</style>
