<template>
    <div
        class="profile-edition__skills_item"
        :class="{'profile-edition__skills_item--disabled': !!props.disabled}"
        :data-testid="`${props.dataTestid}-item`"
    >
        <VJoyTooltip v-bind="{}" :data-testid="`${props.dataTestid}-tooltip`">
            <VJoyTag
                :id="`${props.dataTestid}-tag`"
                variant="secondary"
                class="profile-edition__skills_item__tag"
                :data-testid="`${props.dataTestid}-tag`"
                :selected="props.added"
            >
                <VJoyIcon
                    v-if="isEnabledModeFavorite"
                    class="profile-edition__skills_item__tag__icon"
                    :class="{'profile-edition__skills_item__tag__icon--disabled': isDisabledFavoriteCTA}"
                    :name="favoriteIcon"
                    :data-testid="`${props.dataTestid}-icon-favorite`"
                    @click="handleFavoriteToggle"
                />
                <div class="profile-edition__skills_item__tag__content" :data-testid="`${props.dataTestid}-content`">
                    <VJoyLink
                        v-if="hasLink"
                        :href="props.href!"
                        :rel="isSearchLink ? 'nofollow' : undefined"
                        class="profile-edition__skills_item__tag__link"
                        :data-testid="`${props.dataTestid}-link`"
                    >
                        <div class="profile-edition__skills_item__tag__link__content"><slot /></div>
                    </VJoyLink>
                    <slot v-else />
                </div>
                <VJoyIcon
                    v-if="isAddModeEnabled"
                    class="profile-edition__skills_item__tag__icon"
                    :class="{'profile-edition__skills_item__tag__icon--disabled': isAddCtaDisabled}"
                    name="add"
                    :data-testid="`${props.dataTestid}-icon-add`"
                    @click="handleAdd"
                />
                <VJoyIcon
                    v-if="isEnabledModeRemove"
                    class="profile-edition__skills_item__tag__icon"
                    name="cross"
                    :data-testid="`${props.dataTestid}-icon-remove`"
                    @click="handleRemove"
                />
            </VJoyTag>
            <template v-if="isEnabledTooltip" #tooltip-content>
                <div class="profile-edition__skills_item__tooltip" :data-testid="`${props.dataTestid}-tooltip-content`"><slot /></div>
            </template>
        </VJoyTooltip>
    </div>
</template>
<script setup lang="ts">
    import {computed} from 'vue';
    import {VJoyIcon, VJoyLink, VJoyTag, VJoyTooltip} from '@maltjoy/core-vue';

    /**
     * enabled: visible and clickabke
     * disabled: visible but not clickable
     * hidden: not visible nor clickable
     */
    export type ProfileSkillSetItemCTAMode = 'enabled' | 'disabled' | 'hidden';

    const props = withDefaults(
        defineProps<{
            dataTestid?: string;
            disabled?: boolean;
            added?: boolean;
            favorite?: boolean;
            href?: string;
            isEnabledModeRemove?: boolean;
            isEnabledTooltip?: boolean;
            modeAdd?: ProfileSkillSetItemCTAMode;
            modeFavorite?: ProfileSkillSetItemCTAMode;
        }>(),
        {
            dataTestid: 'profile-skill-set-item',
            disabled: false,
            added: false,
            favorite: false,
            href: undefined,
            isEnabledModeRemove: false,
            isEnabledTooltip: true,
            modeAdd: 'hidden',
            modeFavorite: 'hidden',
        },
    );

    const emit = defineEmits<{
        add: [];
        'favorite:add': [];
        'favorite:remove': [];
        remove: [];
    }>();

    // Add
    const isAddCtaDisabled = computed(() => props.modeAdd === 'disabled');
    const isAddModeEnabled = computed(() => props.modeAdd === 'enabled' || props.modeAdd === 'disabled');
    // Favorite
    const isDisabledFavoriteCTA = computed(() => props.modeFavorite === 'disabled');
    const isEnabledModeFavorite = computed(() => props.modeFavorite === 'enabled' || props.modeFavorite === 'disabled');
    const isFavoriteModeRemove = computed(() => props.favorite === true);
    const favoriteIcon = computed(() => (isFavoriteModeRemove.value ? 'star-fill' : 'star'));

    const hasLink = computed(() => !props.disabled && props.href && props.href.length > 0);
    const isSearchLink = computed(() => props.href?.startsWith('/s?q='));
    const isEnabledTooltip = computed(() => !!props.isEnabledTooltip);

    function handleAdd() {
        if (!props.disabled && !isAddCtaDisabled.value) {
            emit('add');
        }
    }
    function handleFavoriteToggle() {
        if (!props.disabled && !isDisabledFavoriteCTA.value) {
            if (isFavoriteModeRemove.value) {
                emit('favorite:remove');
            } else {
                emit('favorite:add');
            }
        }
    }

    function handleRemove() {
        if (!props.disabled) {
            emit('remove');
        }
    }
</script>
<style lang="scss">
    .profile-edition__skills_item {
        max-width: 100%;

        &__tag {
            &__content,
            &__link__content {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &__content {
                max-width: 250px;
            }
            &__link,
            &__link__content {
                max-width: 100%;
            }

            &__icon {
                cursor: pointer;

                &--disabled {
                    * {
                        color: var(--joy-color-neutral-40);
                    }
                    cursor: not-allowed;
                }
            }
        }

        &__tooltip {
            word-break: break-all;
        }

        &--disabled {
            .joy-tag {
                color: var(--joy-color-neutral-40);
            }

            .profile-edition__skills_item__tag__icon {
                cursor: not-allowed;
            }
        }
    }
</style>
