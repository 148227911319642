import type {LocationPreferences} from '~/types/domain/LocationPreferences';
import type {ActivityLocationResource, WorkplacePreferenceResourceValue} from '~/types/api-raw-types';

export enum WorkplacePreferenceItem {
    ONSITE,
    PARTIALLY_ONSITE,
    REMOTE,
}

export type ActivityLocationItem = {
    city: string;
    radius: number;
    zipcode: string;
};

export type LocationPreferencesItem = {
    city: string;
    preference: WorkplacePreferenceItem;
    addressRadius: number;
    activityLocations: ActivityLocationItem[];
    willingnessToTravelInternationally: boolean;
};

export function toLocationPreferenceItem(locationPreferences: LocationPreferences): LocationPreferencesItem {
    return {
        city: locationPreferences.address.city,
        preference: toWorkplacePreference(locationPreferences.preference),
        addressRadius: locationPreferences.addressRadius,
        activityLocations: locationPreferences.activityLocations.map((al) => toActivityLocationItem(al)),
        willingnessToTravelInternationally: locationPreferences.willingnessToTravelInternationally,
    };
}

function toWorkplacePreference(preference: WorkplacePreferenceResourceValue): WorkplacePreferenceItem {
    switch (preference) {
        case 'ONSITE':
            return WorkplacePreferenceItem.ONSITE;
        case 'PARTIALLY_ONSITE':
            return WorkplacePreferenceItem.PARTIALLY_ONSITE;
        case 'REMOTE':
            return WorkplacePreferenceItem.REMOTE;
    }
}

function toActivityLocationItem(activityLocation: ActivityLocationResource): ActivityLocationItem {
    return {
        city: activityLocation.address.city,
        radius: activityLocation.radius,
        zipcode: activityLocation.address.zipCode,
    };
}
